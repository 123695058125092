<template>
    <div class="page-section" id="news-feed">
        <div class="container position-relative">
            <div class="row mb-5">
                <div class="col-md-3 offset-md-9">
                    <label for="search">Search</label>
                    <div class="d-inline-flex justify-content-between align-items-center">
                        <input class="form-control" name="search" v-on:change="reSearch" v-model="search" placeholder="Keyword"/>
                        <a href="#" v-on:click.prevent="reset" v-if="search != '' && search != null" class="pl-2">
                            <i class="fa fa-times fa-lg text-primary"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4" v-for="(post,i) in news" :key="`${i}-${post.id}`">
                    <a :href="post.url">
                        <img class="img-fluid" :src="post.fields.featuredImage"/>
                    </a>
                    <a class="d-flex justify-content-between align-items-start mt-4 mb-5" :href="post.url">
                        <h5 class="text-primary m-0">{{post.title}}</h5>
                        <i class="fa fa-lg fa-arrow-right pl-2 pt-2"></i>
                    </a>
                </div>
            </div>
            <div class="spinner-container" v-if="isLoading">
                <i class="fa text-primary fa-spinner fa-spin"></i>
            </div>
            <div class="row" v-if="hasPosts">
                <div class="col-12 mt-5 text-center">
                    <a href="#" v-on:click.prevent="loadMore" class="btn btn-main">Load more</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ky from 'ky'

    export default {
        data() {
          return {
              page: 0,
              hasPosts: false,
              news: [],
              search: null,
              isLoading: false
          }
        },
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            }
        },
        created() {
            this.loadMore();
        },
        methods: {
            afterGetPosts(data) {
                this.news = this.news.concat(data.posts);
                this.hasPosts = data.hasPosts;
                this.page = this.page + 1;
                this.isLoading = false;
            },
            afterGetPostsFailure(err) {
                this.isLoading = false;
            },
            reset() {
                this.search = null;
                this.page = 0;
                this.hasPosts = false;
                this.news = [];
                this.loadMore();
            },
            loadMore() {
                this.isLoading = true;
                ky.get('/newsgetposts?page=' + (this.page + 1).toString()).json().then(this.afterGetPosts, this.afterGetPostsFailure);
            },
            reSearch() {
                this.isLoading = true;
                this.page = 0;
                this.hasPosts = false;
                this.news = [];
                ky.get('/newsgetposts?search='+this.search+'&page=' + (this.page + 1).toString()).json().then(this.afterGetPosts, this.afterGetPostsFailure);
            }
        }
    }
</script>
